<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-form-model-item label="小区名称" prop="name">
        <a-input v-model="rowData.name" placeholder="小区名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="小区地址" prop="provinceId">
        <PCD
          ref="pcd"
          placeholder="请选择区域"
          :province.sync="rowData.province"
          :city.sync="rowData.city"
          :district.sync="rowData.area"
          :provinceId.sync="rowData.provinceId"
          :cityId.sync="rowData.cityId"
          :districtId.sync="rowData.areaId"
        >
        </PCD>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="rowData.address" placeholder="详细地址"></a-input>
      </a-form-model-item>
      <!--            <a-form-model-item label="联系人" prop="contacts">-->
      <!--                <a-input v-model="rowData.contacts" placeholder="联系人"></a-input>-->
      <!--            </a-form-model-item>-->
      <!--            <a-form-model-item label="联系电话" prop="mobile">-->
      <!--                <a-input v-model="rowData.mobile" placeholder="联系电话"></a-input>-->
      <!--            </a-form-model-item>-->
      <a-form-model-item label="小区照片">
        <QiniuUpload type="image" :value.sync="rowData.imageUrl"></QiniuUpload>
      </a-form-model-item>
      <a-form-model-item label="楼层格式">
        <a-button @click="handlHouse" type="primary">添加楼层格式</a-button>
      </a-form-model-item>
    </a-form-model>

    <!-- 表格区域 -->
    <div v-for="(item,index) in describeHouseList" :key="index">
      <p></p>
      <a-row>
        <a-col span="3"></a-col>
      <a-col span="12">
        <a-button @click="handleChoose(index)" type="primary" v-if="item.buildingTypeName == ''">选择类型</a-button>
        <a-button type="link" v-if="item.buildingTypeName != ''">类型:{{item.buildingTypeName}}</a-button>
        <a-button @click="handleOk(index)" type="primary">添加楼层内容</a-button>
        <a-button @click="onDelAddres(index)" type="primary">删除</a-button>
      </a-col>
      </a-row>
    <a-row>
      <a-col span="3"></a-col>
      <a-col span="12">
        <a-table
          :columns="columns"
          :data-source="item.describeDetailList"
          bordered
          :pagination="false"
          :rowKey="(record,index) => {return index}"
        >
        <template slot="sort" slot-scope="text, record">
            <a-input v-model="record.sort"></a-input>
          </template>
          <template slot="description" slot-scope="text, record">
            <a-input v-model="record.description"></a-input>
          </template>
          <template slot="desRange" slot-scope="text, record">
            <div>
              <a-input v-model="record.desRange"></a-input>
            </div>
          </template>
        </a-table>
      </a-col>
    </a-row>
    </div>
     <a-row class="info-row" v-if="describeHouseList.length > 0">
      <a-col span="3"> </a-col>
      <a-col>
        <div class="info">
          注：描述为：期/栋/单元/层/房号等; 范围格式数据中间用 <span class="info-color"> ，</span>或<span
            class="info-color"
          >
            - </span
          >号，例：A,B,C ; 1-24
        </div>
      </a-col>
    </a-row>
    <!-- 楼层类型 -->
    <a-modal
    title="选择类型"
    style="top: 8px"
    :width="500"
    v-model="visibleType" 
    @ok="toSubmitType"
    @cancel="handleCancelType"
  >
     <a-form-model
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
     <a-form-model-item label="楼层类型" >
          <a-select placeholder="请选择楼层类型" v-model="houseTypeId">
            <a-select-option :value="item.id" v-for="(item, index) in houseTypeList" :key="index">{{item.title}}</a-select-option>
          </a-select>
      </a-form-model-item>
     </a-form-model>
    </a-modal>
  </a-modal>
   

</template>

<script>
import { editDealerStore, selectByIdDealerStore, addDealerStore } from '../api/DealerStoreApi'
export default {
  data() {
    return {
      visible: false,
      visibleType:false,
      houseTypeId:'',
      houseTypeList:[],
      handle: 'add',
      rowData: {},
      count: 1,
      NumBulno:true,
      statusBulno:true,
      describeHouseList:[],
      describeDetailList: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          key:'sort',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'sort' },
        },
        {
          title: '描述',
          dataIndex: 'description',
          key:'description',
          align: 'center',
          scopedSlots: { customRender: 'description' },
        },
        {
          title: '范围',
          dataIndex: 'desRange',
          key:'desRange',
          align: 'center',
          scopedSlots: { customRender: 'desRange' },
        },
      ],
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入小区名称', trigger: 'blur' }],
        provinceId: [{ required: true, message: '请选择小区地址', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        imageUrl: [{ required: true, message: '请输入门头照片', trigger: 'blur' }],
      },
    }
  },
  methods: {
    handlHouse(){
      if(this.houseTypeList.length > 0){
         this.describeHouseList.push({
        describeDetailList:[],
        buildingTypeName:''
      })
      }else{
        return this.$message.warning('楼层类型已选完，暂不能添加！')
      }
      
    },
    handleChoose(index){
      this.indexNum = index
      this.visibleType = true
    },
    toSubmitType(){
      let list =this.houseTypeList.filter(item=>{
        return item.id === this.houseTypeId
      })
      this.houseTypeList =this.houseTypeList.filter(item=>{
        return item.id != this.houseTypeId
      })
      this.describeHouseList[this.indexNum].buildingTypeName = list[0].title
      this.describeHouseList[this.indexNum].buildingTypeId = list[0].id
      this.visibleType = false
    },
    handleCancelType(){
     this.visibleType = false
    },
    // 删除楼层
    onDelAddres(index){
      if(this.describeHouseList[index].buildingTypeId){
      this.houseTypeList.push({
        id:this.describeHouseList[index].buildingTypeId,
        title:this.describeHouseList[index].buildingTypeName
      })
      }
       this.describeHouseList.splice(index,1)
    },
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
      if(handle == 'add'){
        this.describeDetailList = []
        this.count = 1
      }
      if (handle === 'edit') {
        selectByIdDealerStore(row.id).then((res) => {
          this.rowData = res.body
          this.describeHouseList=res.body.buildingTypeBindList
          if (res.body) {
            this.$set(this.rowData, 'provinceId', res.body.provinceId)
            this.$set(this.rowData, 'province', res.body.province)
            this.$set(this.rowData, 'cityId', res.body.cityId)
            this.$set(this.rowData, 'city', res.body.city)
            this.$set(this.rowData, 'areaId', res.body.areaId)
            this.$set(this.rowData, 'area', res.body.area)
            this.$set(this.rowData, 'address', res.body.address)
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
          }
        })
      }
      // 楼层类型
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '房屋类型').then((res) => {
      this.houseTypeList = res.body || []
      
    })
    },
    handleOk(index) {
      if(!this.describeHouseList[index].buildingTypeName){
        return this.$message.warning('请先选择楼层类型')
      }
      if(this.describeHouseList[index].describeDetailList.length === 0){
        this.count = 1
      }else{
        this.count = this.describeHouseList[index].describeDetailList.length + 1
      }
      this.describeHouseList[index].describeDetailList.push({
        sort: this.count,
        description: '',
        desRange: '',
      })
      this.count = this.count + 1
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        if (this.describeHouseList.length == 0) {
          return this.$notification.error({ message: '请添加楼层格式' })
        }
        this.describeHouseList.forEach(sitem=>{
          this.NumBulno = sitem.describeDetailList.some((item) => {
          return item.description != '' && item.desRange != ''
        })
       
        })
        if (this.NumBulno === false) {
          return this.$notification.error({ message: '楼层描述和范围不能为空' })
        }
         this.describeHouseList.forEach((sitem,index)=>{
           sitem.describeDetailList.forEach(item=>{
               if (item.desRange.indexOf('-') !== -1) {
            let itemArr = item.desRange.split('-')
            if (itemArr.length != 2) {
              return this.$notification.error({ message: '楼层范围格式不正确！' })
            } else if (itemArr.length == 2) {
              let reg = /^[0-9]\d*$/
              this.statusBulno = itemArr.every((item) => {
                item = item* 1 // 转换成数字
                return reg.test(item)
              })
              
            }
          } else {
            item.desRange = item.desRange.replace(/，/g, ',')
          }
           })
         })
         if (this.statusBulno === false) {
                return this.$notification.error({ message: '楼层范围格式不正确' })
              }
        this.rowData.buildingTypeBindList = this.describeHouseList
        const res = this.handle === 'add' ? await addDealerStore(this.rowData) : await editDealerStore(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
          this.describeHouseList = []
        }
      })
    },
    handleCancel() {
      if (this.handle === 'add') {
        this.$refs.form.resetFields()
        this.describeHouseList = []
        this.count = 0
        this.$emit('reload')
      }
      this.visible = false
    },
  },
  created() {
    
  },
}
</script>
<style lang="less" scoped>
.info-row {
  margin-top: 5px;
}
.info {
  color: #f5222d;
  .info-color {
    font-size: 20px;
    color: black !important;
  }
}
</style>
